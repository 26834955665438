import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarOpen: false,
  sidebarIndex: 0,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    toggleSidebar(state, action) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    closeSidebar(state, action) {
      state.sidebarOpen = false;
    },
    setSidebarIndex(state, action) {
      state.sidebarIndex = action.payload;
    },
    nextIndex(state, action) {
      state.sidebarIndex++;
    },
    lastIndex(state, action) {
      state.sidebarIndex--;
    },
    resetIndex(state, action) {
      state.sidebarIndex = 0;
    },
  },
});

// actions
export const sidebarActions = sidebarSlice.actions;

// default
export default sidebarSlice.reducer;
