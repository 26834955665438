import React from "react";
import classes from "./Loading.module.css";
import loadIMG from "../../assets/images/loading.gif";

const Loading = () => {
  return (
    <div className={classes.loading}>
      <img src={loadIMG} alt="loading portfolio page ramteen taheri" />
    </div>
  );
};

export default Loading;
