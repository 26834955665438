import React, { Fragment, Suspense } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loading from "../Loading/Loading";
const Main = React.lazy(() => import("../../pages/Main/Main"));
const About = React.lazy(() => import("../../pages/About/About"));
const Education = React.lazy(() => import("../../pages/Education/Education"));
const Skills = React.lazy(() => import("../../pages/Skills/Skills"));
const Projects = React.lazy(() => import("../../pages/Projects/Projects"));
const Contact = React.lazy(() => import("../../pages/Contact/Contact"));
// const Loading = React.lazy(() => import("../Loading/Loading"));

const PageRoutes = () => {
  return (
    <Router>
      {/* Using the new React-Router v6 */}
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Main Page */}
          <Fragment>
            <Route path="/" element={<Main />} />
          </Fragment>

          {/* About Page */}
          <Fragment>
            <Route path="/about" element={<About />} />
          </Fragment>

          {/* Education Page */}
          <Fragment>
            <Route path="/education" element={<Education />} />
          </Fragment>

          {/* Education Page */}
          <Fragment>
            <Route path="/skills" element={<Skills />} />
          </Fragment>

          {/* Projects Page */}
          <Fragment>
            <Route path="/projects" element={<Projects />} />
          </Fragment>

          {/* Contact Page */}
          <Fragment>
            <Route path="/contact" element={<Contact />} />
          </Fragment>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default PageRoutes;
